import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import QuilmesMusicInfo from "../../components/quilmes-musica/quilmesMusicInfo"
import styles from "../../styles/mainCardMusic.module.scss"
import QuilmesForm from "../../components/quilmes-musica/quilmesForm"
import YaParticipandoInfo from "../../components/quilmes-musica/yaestasparticipando.js"

const MainCard = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const events = data.allEventos.nodes
  const locationData = location.state?.event
  const provincia = location.state?.provincia

  console.log("locationData", locationData)

  useEffect(() => {
    if (!data || !location.state) navigate("/quilmesfestivales/formulario")
  })

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [showForm, formSent])

  useEffect(() => {
    !locationData && setShowForm(true)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} style={{ height: "100%" }}>
        <div className={styles.left}></div>
      </div>
      <div
        className={`${styles.right} ${showForm ? styles.customStyle : ""} ${
          formSent ? styles.customStyle : ""
        }`}
      >
        {showForm === false && formSent === false && (
          <QuilmesMusicInfo
            active={locationData?.active}
            date={locationData?.rangoFecha}
            location={locationData?.localidad.toUpperCase()}
            title={locationData?.titulo.toUpperCase()}
            texto={locationData?.texto}
            comollego={locationData?.comoLlegar}
            txtBtn1="Participá por entradas"
            txtBtn2="Cómo llegar"
            setForm={setShowForm}
            urlProvincia={locationData?.provincia
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .replace(/[\s]/g, "_")}
          />
        )}

        {showForm === true && formSent === false && (
          <QuilmesForm
            location={locationData}
            title="Completá tus datos y participá por entradas"
            events={events}
            showForm={setShowForm}
            showParticipation={setFormSent}
            btnTxt="Participá por entradas"
          />
        )}

        {showForm === false && formSent === true && (
          <YaParticipandoInfo
            showForm={setShowForm}
            showParticipation={setFormSent}
            title="!Estás a un paso de la música!"
            urlProvincia={provincia}
          />
        )}
      </div>
    </div>
  )
}

export default MainCard

export const data = graphql`
  query Musica2023Form {
    allEventos {
      nodes {
        id
        fecha
        imgBack
        comoLlegar
        latitud
        longitud
        localidad
        month
        provincia
        texto
        titulo
        active
      }
    }
  }
`
