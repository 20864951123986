import React, { useState, useEffect } from "react"
import { Alert } from "react-bootstrap"
import localidades from "./localidades.json"
import Cookies from "js-cookie"
import styles from "../../styles/eventForm.module.scss"
import logoQuilmes from "../../images/quilmesMusica/svg/quilmes-web-01.svg"
import backArrow from "../../images/quilmesMusica/svg/quilmes-web-18.svg"
import { IsMobileDevice } from "../../../utils/ismobile"
import { formatDOB } from "../../../utils/functions"
import Bowser from "bowser"

const QuilmesForm = ({
  location,
  showForm,
  showParticipation,
  title,
  btnTxt,
  events,
}) => {
  const emptyform = {
    name: "",
    mail: "",
    dob: "",
    state: "",
    location: "",
    reason: "",
    event: "",
  }
  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"

  const [errorMsg, setErrorMsg] = useState("")
  const [form, setForm] = useState(emptyform)
  const [checked, setChecked] = useState(false)
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [browserName, setBrowserName] = useState("")

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    const {
      parsedResult: {
        browser: { name },
      },
    } = Bowser.getParser(window.navigator.userAgent)
    setBrowserName(name)
  }, [])

  const handleChange = ({ target }) => {
    const { name, value } = target
    setForm({ ...form, [name]: value })
    if (name === "dob" && form.dob !== "") {
      setShowPlaceholder(false)
    }
  }

  const handleBirthDate = date => {
    if (date.length < 9) {
      return true
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (form.name.length < 1) {
      setErrorMsg("El nombre es demasiado corto")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Error en el nombre`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }
    if (form.mail.length < 3) {
      setErrorMsg("El email es demasiado corto")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Error en el email`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }
    if (handleBirthDate(form.dob)) {
      setErrorMsg("Seleccione una fecha valida DD/MM/AAAA")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Falta ingresar fecha de nacimiento`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }
    if (!form.state) {
      setErrorMsg("Debe seleccionar una provincia")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Falta seleccionar provincia`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }
    if (!form.location) {
      setErrorMsg("Debe seleccionar una localidad")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Falta seleccionar localidad`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }
    if (!form.reason) {
      setErrorMsg("Debe seleccionar un motivo")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Falta seleccionar motivo`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }

    const date = new Date()
    const year = date.getFullYear()

    if (form.dob.length < 10)
      return setErrorMsg("Fecha de nacimiento inválida.")
    if (Number(form.dob.split("/")[0]) > 31)
      return setErrorMsg("Fecha de nacimiento inválida.")
    if (Number(form.dob.split("/")[1]) > 12)
      return setErrorMsg("Fecha de nacimiento inválida.")
    if (Number(form.dob.split("/")[2]) >= year)
      return setErrorMsg("Fecha de nacimiento inválida.")
    if (Number(form.dob.split("/")[2]) < year - 120)
      return setErrorMsg("Fecha de nacimiento inválida.")
    setErrorMsg("")

    if (!form.event) {
      setErrorMsg("Debe seleccionar un evento")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Falta seleccionar evento`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }

    if (!checked) {
      setErrorMsg("Debe aceptar los TyC")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: `Falta aceptar terminos y condiciones`,
        interaction: true,
        component_name: "boton_participa",
      })
      return
    }

    let td = Cookies.get("_td")
    let obj = {
      fullname: form.name,
      dateofbirth: form.dob,
      email: form.mail,
      state: form.state,
      city: form.location,
      event: form.event,
      preferenceQuestion: form.reason,
      td,
    }

    // showParticipation(true)
    // showForm(false)

    fetch(`${api}/musica2023`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    }).then(response => {
      console.log("responsee", response)
      if (response.ok) {
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Form",
          event_action: "Success",
          event_label: ``,
          interaction: true,
          component_name: "boton_participa",
          element_text: "Participá por entradas",
          userUid: td,
        })
        showParticipation(true)
        showForm(false)
      }
    })
  }

  const handleBackArrow = () => {
    showForm(false)
    showParticipation(false)
  }

  return (
    <div className={styles.formContainer}>
      <div className={styles.formBox}>
        <div className={styles.firstBlock}>
          <h2>{title}</h2>
          {location && (
            <img
              onClick={handleBackArrow}
              className={styles.backArrow}
              src={backArrow}
              alt="back-arrow"
            />
          )}
        </div>

        <div className={styles.containerBox}>
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div style={{ width: isMobile ? "100%" : "50%" }}>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  placeholder="NOMBRE Y APELLIDO"
                  onChange={handleChange}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 1",
                      event_label: `Nombre y Apellido`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                />
              </div>

              <div
                style={{
                  width: isMobile ? "100%" : "50%",
                  marginLeft: isMobile ? "" : "30px",
                }}
              >
                <input
                  type="email"
                  name="mail"
                  value={form.mail}
                  placeholder="EMAIL"
                  onChange={handleChange}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 2",
                      event_label: `Email`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div style={{ width: isMobile ? "100%" : "50%" }}>
                <input
                  type="text"
                  name="dob"
                  value={form.dob}
                  placeholder={"FECHA DE NACIMIENTO"}
                  onChange={({ target }) => {
                    const onlyNums = target.value.replace(/[^0-9]/g, "")
                    const date = formatDOB(onlyNums)
                    setForm({ ...form, dob: date })
                  }}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 3",
                      event_label: `Fecha de nacimiento`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                />
              </div>

              <div
                style={{
                  width: isMobile ? "100%" : "50%",
                  marginLeft: isMobile ? "" : "30px",
                }}
              >
                <select
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                  className={styles.localidadSelector}
                  style={{
                    color: form.state ? "#67e9d8" : "rgba(255, 255, 255, 0.81)",
                    fontSize: form.state ? "22px" : "20px",
                  }}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 4",
                      event_label: `Provincia`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                >
                  <option selected hidden>
                    PROVINCIA
                  </option>
                  <option value="Capital Federal"> Capital Federal </option>
                  <option value="Buenos Aires">Buenos Aires</option>
                  <option value="Catamarca">Catamarca</option>
                  <option value="Chaco">Chaco</option>
                  <option value="Chubut">Chubut</option>
                  <option value="Cordoba">Córdoba</option>
                  <option value="Corrientes">Corrientes</option>
                  <option value="Entre Rios">Entre Ríos</option>
                  <option value="Formosa">Formosa</option>
                  <option value="Jujuy">Jujuy</option>
                  <option value="La Pampa">La Pampa</option>
                  <option value="La Rioja">La Rioja</option>
                  <option value="Mendoza">Mendoza</option>
                  <option value="Misiones">Misiones</option>
                  <option value="Neuquen">Neuquén</option>
                  <option value="Rio Negro">Río Negro</option>
                  <option value="Salta">Salta</option>
                  <option value="San Juan">San Juan</option>
                  <option value="San Luis">San Luis</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Santa Fe">Santa Fe</option>
                  <option value="Santiago Del Estero">
                    Santiago del Estero
                  </option>
                  <option value="Tierra Del Fuego">Tierra del Fuego</option>
                  <option value="Tucuman">Tucumán</option>
                </select>
              </div>
            </div>

            <div className={styles.row}>
              <div style={{ width: isMobile ? "100%" : "50%" }}>
                <select
                  name="location"
                  value={form.location}
                  onChange={handleChange}
                  className={styles.localidadBarrio}
                  style={{
                    color: form.location
                      ? "#67e9d8"
                      : "rgba(255, 255, 255, 0.81)",
                    fontSize: form.location ? "22px" : "16px !important",
                  }}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 5",
                      event_label: `Localidad`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                >
                  <option>LOCALIDAD</option>
                  {localidades
                    .sort((a, b) => {
                      const n = a.Localidad.localeCompare(b.Localidad)
                      return n == 0 && a.Localidad !== n.Localidad
                        ? b.Localidad.localeCompare(a)
                        : n
                    })
                    .filter(localidad => {
                      return localidad.Provincia === form.state
                    })
                    .map(option => (
                      <option key={option.Localidad} value={option.Localidad}>
                        {option.Localidad}
                      </option>
                    ))}
                </select>
              </div>

              <div
                style={{
                  width: isMobile ? "100%" : "50%",
                  marginLeft: isMobile ? "" : "30px",
                }}
              >
                <select
                  name="reason"
                  value={form.reason}
                  onChange={handleChange}
                  style={{
                    color: form.reason
                      ? "#67e9d8"
                      : "rgba(255, 255, 255, 0.81)",
                    fontSize: form.reason ? "22px" : "20px",
                    textTransform: "uppercase",
                  }}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 6",
                      event_label: `Cuál es tu ocasión favorita para una quilmes?`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                >
                  <option selected hidden>
                    Cuál es tu ocasión favorita para una quilmes?
                  </option>
                  <option value="Cuando voy a la cancha">
                    {" "}
                    Cuando voy a la cancha
                  </option>
                  <option value="Mirando un partido de fútbol">
                    Mirando un partido de fútbol
                  </option>
                  <option value="Cuando voy a recital">
                    Cuando voy a recital
                  </option>
                  <option value="Cocinando o comiendo en casa">
                    Cocinando o comiendo en casa
                  </option>
                  <option value="Cuando voy a comer afuera">
                    Cuando voy a comer afuera
                  </option>
                  <option value="Con amigos en casa o en un bar">
                    Con amigos en casa o en un bar
                  </option>
                  <option value="Mirando TV o escuchando música">
                    Mirando TV o escuchando música
                  </option>
                  <option value="No tomo cerveza">No tomo cerveza</option>
                </select>
              </div>
            </div>
            <div className={styles.row}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  margin: "10px 0",
                }}
              >
                <h1 className={styles.eventSelector} style={{ color: "white" }}>
                  Evento
                </h1>
                <select
                  name="event"
                  onChange={handleChange}
                  value={form.event}
                  style={{
                    color: form.event ? "#67e9d8" : "rgba(255, 255, 255, 0.81)",
                    fontSize: form.event ? "22px" : "20px",
                    textTransform: "uppercase",
                  }}
                  onFocus={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 7",
                      event_label: `Seleccionar evento`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                >
                  <option>SELECCIONAR EVENTO</option>
                  {events
                    .filter(event => event.active)
                    .map(evento => {
                      return (
                        <option
                          value={`${evento.titulo}`}
                        >{`${evento.titulo}`}</option>
                      )
                    })}
                </select>
              </div>
            </div>

            <div>
              <div className={styles.remember}>
                <input
                  id="remember"
                  type="checkbox"
                  name="checkbox"
                  value="accepted"
                  checked={checked}
                  onChange={({ target }) => {
                    setChecked(target.checked)

                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Form",
                      event_action: "Paso 8",
                      event_label: `tyc`,
                      interaction: true,
                      component_name: "boton_participa",
                    })
                  }}
                />
                <label for="remember">
                  He leído y acepto{" "}
                  <a
                    className={styles.linksa}
                    href="https://www.quilmes.com.ar/politicadeprivacidad.pdf"
                    target="_blank"
                  >
                    {" "}
                    Bases y Condiciones
                  </a>
                  ,
                  <a
                    className={styles.linksa}
                    href="https://www.quilmes.com.ar/politicadeprivacidad.pdf"
                    target="_blank"
                  >
                    {" "}
                    Políticas de Privacidad
                  </a>
                  ,
                  <a
                    className={styles.linksa}
                    href="https://www.quilmes.com.ar/terminosycondiciones.pdf"
                    target="_blank"
                  >
                    {" "}
                    Términos y Condiciones
                  </a>{" "}
                  y activaciones de marketing
                </label>
              </div>
            </div>
            <Alert
              variant="danger"
              show={errorMsg !== ""}
              onClose={() => setErrorMsg("")}
              dismissible
            >
              {errorMsg}
            </Alert>
            <div className={styles.buttonContainer}>
              <button type="submit">{btnTxt}</button>
            </div>
          </form>
        </div>

        <div>
          <img className={styles.logo} src={logoQuilmes} alt="logo-quilmes" />
        </div>
      </div>
    </div>
  )
}

export default QuilmesForm
