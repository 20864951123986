import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "../../styles/eventInfo.module.scss"
import logoQuilmes from "../../images/quilmesMusica/svg/quilmes-web-01.svg"
import closeIcon from "../../images/quilmesMusica/svg/quilmes-web-17.svg"
import mapIcon from "../../images/quilmesMusica/svg/quilmes-web-19.svg"
import calendarIcon from "../../images/quilmesMusica/svg/quilmes-web-20.svg"
import { formatDateRange } from "../../../utils/functions"

const QuilmesMusicInfo = ({
  active,
  date,
  location,
  title,
  texto,
  txtBtn1,
  txtBtn2,
  setForm,
  comollego,
  urlProvincia,
}) => {
  function handleClick() {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Content",
      event_action: `${title}`,
      event_label: `Participá por entradas`,
      interaction: true,
      component_name: "boton_entradas",
      element_text: "Participá por entradas",
    })
    setForm(true)
  }

  function handleClose() {
    navigate(`/quilmesfestivales/provincia?prov=${urlProvincia}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.child1}>
        <div className={styles.dateContainer}>
          <img
            className={styles.calendarIcon}
            src={calendarIcon}
            alt="calendar-icon"
          />
          <h2>{formatDateRange(date)}</h2>
        </div>
      </div>

      <img
        onClick={() => handleClose()}
        className={styles.closeIcon}
        src={closeIcon}
        alt="close-icon"
      />

      <div className={styles.child2}>
        <div className={styles.location}>
          <img className={styles.mapIcon} src={mapIcon} alt="map-icon" />
          <p>{location}</p>
        </div>

        <h1>{title}</h1>
        <h3>{texto}</h3>
      </div>

      <div className={styles.child3}>
        <div className={styles.firstButton}>
          {!active && (
            <button className={styles.firstBtn} onClick={() => handleClick()}>
              {txtBtn1}
            </button>
          )}
          <a
            href={comollego}
            target="_blank"
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Content",
                event_action: `${title}`,
                event_label: `Como llegar`,
                interaction: true,
                component_name: "boton_como_llegar",
                element_text: "Cómo llegar",
              })
            }}
          >
            <button className={styles.secondBtn}>{txtBtn2}</button>
          </a>
        </div>
      </div>

      <div className="child-3">
        <img className={styles.logo} src={logoQuilmes} alt="logo-quilmes" />
      </div>
    </div>
  )
}

export default QuilmesMusicInfo
