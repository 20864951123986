import React, { useState, useEffect } from "react"
import logoQuilmes from "../../images/quilmesMusica/svg/quilmes-web-01.svg"
import closeIcon from "../../images/quilmesMusica/svg/quilmes-web-17.svg"
import orejaIcon from "../../images/quilmesMusica/svg/quilmes-web-13.svg"
import twitterIcon from "../../images/quilmesMusica/svg/quilmes-musica-37.svg"
import instagramIcon from "../../images/quilmesMusica/svg/quilmes-musica-36.svg"
import youtubeIcon from "../../images/quilmesMusica/svg/quilmes-musica-38.svg"

import styles from "../../styles/participando.module.scss"
import { navigate } from "gatsby"

const YaParticipandoInfo = ({ title, urlProvincia }) => {
  function handleClose() {
    navigate(`/quilmesfestivales`)
  }
  return (
    <div className={styles.container}>
      <div className={styles.firstBlock}>
        <div>
          <h1>{title}</h1>
        </div>
      </div>

      <img
        onClick={() => handleClose()}
        className={styles.closeIcon}
        src={closeIcon}
        alt="back-arrow"
      />

      <div className={styles.secondBlock}>
        <div className={styles.iconBlock}>
          <div className={styles.orejaBlock}>
            <img
              src={orejaIcon}
              alt="oreja-icon"
              className={styles.orejaIcon}
            />
          </div>
        </div>

        <div className={styles.middleBlockText}>
          <div className={styles.middleFirstText}>
            <h1>Ya estás participando</h1>
          </div>
          <div className={styles.middleSecondText}>
            <h2>
              En caso de ganar, nos estaremos comunicando con vos vía mail.
            </h2>
          </div>
        </div>

        <div className={styles.socialsBlock}>
          <div className={styles.textSocials}>
            <h2>seguinos y enterate</h2>
          </div>
          <div className={styles.socials}>
            <a
              target="_blank"
              href="https://twitter.com/Quilmes_Cerveza"
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Social",
                  event_action: "Click",
                  event_label: `https://twitter.com/Quilmes_Cerveza`,
                  interaction: true,
                  component_name: "boton_twitter",
                  element_text: "Twitter",
                })
              }}
            >
              <img
                className={styles.instagram}
                src={instagramIcon}
                alt="instagram-icon"
              />
            </a>

            <a
              target="_blank"
              href="https://www.instagram.com/cervezaquilmes/?hl=es-la"
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Social",
                  event_action: "Click",
                  event_label: `https://www.instagram.com/cervezaquilmes/?hl=es-la`,
                  interaction: true,
                  component_name: "boton_instagram",
                  element_text: "Instagram",
                })
              }}
            >
              <img
                className={styles.twitter}
                src={twitterIcon}
                alt="twitter-icon"
              />
            </a>

            <a
              target="_blank"
              href="https://www.youtube.com/user/Quilmes120Argentina"
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Social",
                  event_action: "Click",
                  event_label: `https://www.youtube.com/user/Quilmes120Argentina`,
                  interaction: true,
                  component_name: "boton_youtube",
                  element_text: "Youtube",
                })
              }}
            >
              <img
                className={styles.youtube}
                src={youtubeIcon}
                alt="youtube-icon"
              />
            </a>
          </div>
        </div>

        <div className={styles.buttonBlock}>
          <div>
            <button
              type="submit"
              className={styles.button}
              onClick={() => {
                navigate("/quilmesfestivales/eventos")
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Content",
                  event_action: "Card",
                  event_label: "Descubrí más música",
                  interaction: true,
                  component_name: "boton_descubri_musica",
                  element_text: "Descubrí más música",
                })
              }}
            >
              Descubrí más música
            </button>
          </div>
        </div>
      </div>

      <div className={styles.logoBlock}>
        <img className={styles.logo} src={logoQuilmes} alt="logo-quilmes" />
      </div>
    </div>
  )
}

export default YaParticipandoInfo
